import Chart from "../../components/Chart/Chart.vue";
import VueRecaptcha from 'vue-recaptcha';
import html2canvas from 'html2canvas'; // 转为图片
import printJS from 'print-js' // 打印
import disableScroll from 'disable-scroll';
export default {
	name: "WarrantWatch",
	created () {
		this.$nextTick(function () {
			this.GetDeployedMarketInsightsAlways()

		});
	},
	mounted () {

	},
	data () {
		return {
			optionData: [],
			contentData: [],
			emailContentData: [],
			textName: '',
			fvData: true,
			email: '',
			ModalText: '',
			Disabled: true,
			checkCaptch: false,
			checkedTo: false,
			Eeceive: 0,
			recapctchacode: '',
			mouse: false,
		};
	},
	computed: {

	},
	watch: {

	},
	methods: {
		GetDeployedMarketInsightsAlways: function () {
			$.ajax({
				type: "POST",
				dataType: 'json',
				url: this.$$.mibb + "GetDeployedMarketInsights",
				data: { marketinsightsid: '', lang: localStorage.getItem('lang') || 1 },
				success: (data) => {
					if (data.status == 'SUCCESS') {
						this.optionData = data.text
						setTimeout(function () {
							$('#WarrantWatch').find('.option').find('li').eq(0).addClass('WarrantWatchmark')
							let H1 = $('#WarrantWatch').find('.option').find('li').eq(0).outerHeight()
							let H2 = $('#WarrantWatch').find('.option').find('li').eq(1).outerHeight()
							let H3 = $('#WarrantWatch').find('.option').find('li').eq(2).outerHeight()
							if ($(window).width() <= 992) {
								$('#WarrantWatch>.mainContent>.scope:nth-child(2)>ul').css({
									'max-height': H1 + H2 + H3 + 30
								})
							} else {
								$('#WarrantWatch>.mainContent>.scope:nth-child(2)>ul').css({
									'max-height': ''
								})
							}
							$(window).resize(function () {
								H1 = $('#WarrantWatch').find('.option').find('li').eq(0).outerHeight()
								H2 = $('#WarrantWatch').find('.option').find('li').eq(1).outerHeight()
								H3 = $('#WarrantWatch').find('.option').find('li').eq(2).outerHeight()
								if ($(window).width() <= 992) {
									$('#WarrantWatch>.mainContent>.scope:nth-child(2)>ul').css({
										'max-height': H1 + H2 + H3 + 30
									})
								} else {
									$('#WarrantWatch>.mainContent>.scope:nth-child(2)>ul').css({
										'max-height': ''
									})
								}
							})


						}, 10);
						if (data.text.length !== 0) {
							this.GetDeployedMarketInsights(data.text[0].marketinsightsid)
						} else {
							this.fvData = false
						}
					}
				},
				error: function (XMLHttpRequest) { }
			});
		},
		GetDeployedMarketInsights: function (marketinsightsid) {
			this.contentData = []
			this.emailContentData = []
			$.ajax({
				type: "POST",
				dataType: 'json',
				url: this.$$.mibb + "GetDeployedMarketInsights",
				data: { marketinsightsid: marketinsightsid, lang: localStorage.getItem('lang') || 1 },
				success: (data) => {
					if (data.status == 'SUCCESS') {
						if (data.text.length !== 0) {
							this.textName = data.text[0].topic
							console.log(data);
							$.each(data.text, (i, c) => {
								this.contentData.push({
									nameData: [{
										name: this.$t('Und.WT'),
										namef: 'Warrant Type',
										data: c.type
									},
									{
										name: this.$t('hp.CP'),
										namef: 'Current Price',
										data: c.BID !== "N/A" ? this.$$.curreryRetrun(c.currency) + " " + this.$$.formatNumber(c.BID, 3, 1) : "N/A"
									},
									{
										name: this.$t('WSe.EP'),
										namef: 'Exercise Price',
										data: c.exercise_price !== "N/A" ? c.underlying_curr + " " + this.$$.formatNumber(c.exercise_price, 3, 1) : "N/A"
									},
									{
										name: this.$t('WSe.ER'),
										namef: 'Exercise Ratio',
										data: c.conv_ratio !== "N/A" ? this.$$.formatNumber(c.conv_ratio, 3, 1) : "N/A"
									},
									{
										name: this.$t('WSe.Mo'),
										namef: 'Moneyness',
										data: c.moneyness_c !== "N/A" ? c.moneyness_c : 'N/A'
									},
									{
										name: this.$t('int.Pr'),
										namef: 'Premium',
										data: c.premium !== 'N/A' ? this.$$.formatNumber(c.premium, 1, 1) + " %" : "N/A",
										top: 80,
										left: 0,
									},
									{
										name: this.$t('int.EG'),
										namef: 'Effective Gearing',
										data: c.effective_gearing !== 'N/A' ? this.$$.formatNumber(c.effective_gearing, 1, 1) + " x" : "N/A",
										top: 70,
										left: 0,
									},
									{
										name: this.$t('hp.Ex'),
										namef: 'Expiry',
										data: `${this.$$.formatDate(c.maturity)} (${this.intervalDate(new Date(c.maturity.replace(/\-/g, "/")), new Date())})`
									}
									],
									overallData: c
								})
								this.emailContentData.push({
									"effective_gearing": c.effective_gearing !== 'N/A' ? this.$$.formatNumber(c.effective_gearing, 1, 1) + " x" : "N/A",
									"conv_ratio": c.conv_ratio !== "N/A" ? this.$$.formatNumber(c.conv_ratio, 3, 1) : "N/A",
									"maturity": `${this.$$.formatDate(c.maturity)} (${this.intervalDate(new Date(c.maturity), new Date())})`,
									"exercise_price": c.exercise_price !== "N/A" ? c.underlying_curr + " " + this.$$.formatNumber(c.exercise_price, 3, 1) : "N/A",
									"description": c.description,
									"dsply_nmll": c.dsply_nmll,
									"type": c.type,
									"premium": c.premium !== 'N/A' ? this.$$.formatNumber(c.premium, 1, 1) + " %" : "N/A",
									"topic": c.topic,
									"moneyness_c": c.moneyness_c !== "N/A" ? c.moneyness_c : 'N/A',
									"BID": c.BID
								})
							})
						}

					}
				},
				error: function (XMLHttpRequest) { }
			});
		},
		onVerify: function (response) {
			this.recapctchacode = response;
			if (response == "") {
				this.checkCaptch = false;
			} else {
				this.checkCaptch = true;
				if (this.checkCaptch && this.checkedTo) {
					this.Disabled = false
				} else {
					this.Disabled = true
				}
			}
		},
		onExpired: function () {
			this.checkCaptch = false;
			this.Disabled = true;
		},
		StructureYourWarrant: function () {
			if ($('.form-check-input').is(":checked") && this.checkCaptch == true) {
				if (this.email) {
					this.Disabled = true;
					$.ajax({
						type: "GET",
						url: this.$$.mibbmail + "WarrantWatch",
						data: { email: this.email, code: this.recapctchacode, title: this.textName, marketinsights: JSON.stringify(this.emailContentData), receive: this.Eeceive },
						dataType: "json",
						success: (data) => {
							if (data.status === "SUCCESS") {
								this.ModalText = 'Thank you for your feedback.'
								// 模态框出现
								$('#WarrantWatchModal').modal('show')
								setTimeout(() => {
									this.$router.go(0);
								}, 1000);
							}
						},
						error: function (error) {
							console.log(error);
						}
					});
				} else {
					this.Disabled = false
					this.ModalText = 'Please fill in all the fields.'
					// 模态框出现
					$('#WarrantWatchModal').modal('show')
				}
			} else {
				// 模态框出现
				$('#WarrantWatchModal').modal('show')
				this.ModalText = 'Please accept the disclaimer.'
			}
		},
		StructureYourWarrantInput: function (v, e) {
			this[v] = e.target.value
		},
		StructureYourWarrantChange: function (e) {
			this.checkedTo = e.target.checked
			if (this.checkedTo && this.checkCaptch) {
				this.Disabled = false
			} else {
				this.Disabled = true
			}
		},
		WarrantChange: function (e) {
			this.Eeceive = e.target.checked
		},
		// 时间计算
		intervalDate: function (old, news) {
			// this.$$.formatNumber(((old - news) / (1 * 24 * 60 * 60 * 1000)) / 30, 0, 0) 
			return this.$$.formatNumber(((old - news) / (1 * 24 * 60 * 60 * 1000)) / 30, 0, 0) < 0 ? 'Expired Warrant' : this.$$.formatNumber(((old - news) / (1 * 24 * 60 * 60 * 1000)) / 30, 0, 0) + this.$t('WSe.mos')
		},
		selectContent: function (index, marketinsightsid) {
			$('#WarrantWatch').find('.option').find('li').eq(index).addClass('WarrantWatchmark')
			$('#WarrantWatch').find('.option').find('li').eq(index).siblings('li').removeClass('WarrantWatchmark')
			this.GetDeployedMarketInsights(marketinsightsid)
		},
		/*打印table*/
		correlationPrintTbale: function () {
			$('#WarrantWatchPrint').show()
			$("#WarrantWatchConBox").hide();
			$('#functionality').hide()
			$('#WarrantWatchPrint').append($('#WarrantWatchConBox').find('.messageContent').find('.Print1').clone())
			$('#WarrantWatchPrint').append($('#WarrantWatchConBox').find('.messageContent').find('.Print2').clone())
			window.scrollTo(0, 0);
			disableScroll.on();
			html2canvas(this.$refs.WarrantWatchPrint, {
				backgroundColor: '#ffffff',
				useCORS: true,
				height: document.body.scrollHeight,
				width: document.body.scrollWidth
			}).then((canvas) => {
				const url = canvas.toDataURL("image/png")
				this.img = url
				// 打印图片
				printJS({
					printable: url,
					type: 'image',
					documentTitle: '',
					onLoadingStart: function () {
						$('#WarrantWatchPrint').hide()
						$("#WarrantWatchConBox").show();
						$('#functionality').show()
						$('#WarrantWatchPrint').empty();
						disableScroll.off();
					}
				})
			})
		},
	},
	components: {
		Chart,
		VueRecaptcha
	}
};